import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


import { StatusLabelComponent, LoaderComponent, ValidationErrorsComponent} from './components';

import { TableModule, SpinnerModule } from './modules'




@NgModule({
  imports: [
    CommonModule,
    NgbModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    TableModule,
    SpinnerModule
  ],
  declarations: [
    StatusLabelComponent,
    LoaderComponent,
    ValidationErrorsComponent  
  ],
  exports: [
    NgbModule,

    StatusLabelComponent,
    LoaderComponent,
    ValidationErrorsComponent,
    
    TableModule,
    SpinnerModule
  ]
})
export class SharedModule { }
