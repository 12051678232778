import { Component } from '@angular/core';
import { RouterExtService } from './track-shipment/services/router-ext.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'postx-corp-web';
  constructor(routerExtService: RouterExtService) {
    routerExtService.loadRouting();
  }
}
