import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  formateAddress(address) {
    return(address.address_1+
    (address.address_2?', ' + address.address_2:'')+
    (address.city?', ' + address.city:'')+
    (address.state?', ' + address.state:'')+
    (address.country?', ' + address.country:'')+
    (address.zip?', ' + address.zip:''))
  }
}
