import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';


export const AppRoutes: Routes = [
  {
    path: '',
    component: ContentLayoutComponent, 
    children: [
      {
        path: '',
        loadChildren: 'app/track-shipment/track-shipment.module#TrackShipmentModule',
      }
    ]
  }
  
]
// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }
