import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { HttpClientModule } from '@angular/common/http';



import { AppRoutes } from './app-routing.module';
import { AppComponent } from './app.component';

import { SharedModule } from '@app/shared'
import { LayoutModule } from '@app/layout/layout.module';

import { ApiService} from './shared/services';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    RouterModule.forRoot(AppRoutes, {useHash: true}),
    LayoutModule,
    SharedModule
  ],
  providers: [
    ApiService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
